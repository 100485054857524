import React, { useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby';

import * as webSocketActions from '../../../redux/actions/webSocketActions';

import { Button } from '@mui/material';

import TOC from '../../../components/TOC';
import ZipCodeField from '../ZipCodeField';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

import '../index.css';
import './index.css';
import { ProviderLogos } from '../../Landing';

function Article({ siteConfig, serviceAddress }) {
  const [showTrap, setShowTrap] = useState(null);
  return (
    <div className="article-container">
      <h1>
        <div>Energy 101 - Who’s who in the Texas Electric Market</div>
      </h1>
      <div className="page-container text-container energy-101">
        <h5 className="est-read-time">
          Published: 11/13/2024 | Read time {'< '}5 min
        </h5>

        <div className="page">
          <div className="inner">
            <TOC></TOC>
            <div className="article-text">
              <h2>Introduction</h2>

              <img
                src={withPrefix('/images/blog/tpuc.png')}
                alt="PUCT logo"
                style={{
                  width: '200px',
                  paddingLeft: '20px',
                  float: 'right'
                }}
              />

              <img
                src={withPrefix('/images/blog/ercot.svg')}
                alt="ERCOT logo"
                style={{
                  width: '200px',
                  paddingLeft: '20px',
                  float: 'right'
                }}
              />

              <p>
                The Electric Reliability Council of Texas (ERCOT) manages the
                flow of electric power to about 90 percent of the state’s
                electric load, including 8 million premises in competitive
                choice areas.{' '}
              </p>
              <p>
                The Public Utility Commission of Texas (PUCT) regulates the
                state’s electric, telecommunication, and water and sewer
                utilities, implements respective legislation, and offers
                customer assistance in resolving consumer complaints.
              </p>
              <p>
                Together they oversee the entire process of electricity
                generation, distribution, and sale in Texas.
              </p>

              <div className="inner-content">
                <Stepper nonLinear activeStep={-1} orientation="vertical">
                  <Step expanded>
                    <StepLabel>
                      <h2>Power Generators</h2>
                    </StepLabel>
                    <StepContent>
                      <p>
                        Power Generators own and operate facilities that create
                        electricity. These facilities include coal, natural gas,
                        and nuclear power plants, as well as renewable energy
                        sources like wind and solar. Power generation companies
                        sell power to retail electricity providers.
                      </p>

                      <div className="image-list">
                        <img
                          src={withPrefix('/images/blog/power-generator-1.svg')}
                          alt="Gas Power"
                        />
                        <img
                          src={withPrefix('/images/blog/power-generator-2.svg')}
                          alt="Coal Power"
                        />
                        <img
                          src={withPrefix('/images/blog/power-generator-3.svg')}
                          alt="Wind Power"
                        />
                        <img
                          src={withPrefix('/images/blog/power-generator-4.svg')}
                          alt="Solar Power"
                        />
                      </div>
                    </StepContent>
                  </Step>

                  <Step expanded>
                    <StepLabel>
                      <h2>Transmission and Distribution Utility (TDU)</h2>
                    </StepLabel>
                    <StepContent>
                      <p>
                        Transmission and Distribution Utility companies are
                        responsible for the transmission and distribution of
                        electricity service to all homes and businesses within a
                        service area. A TDU owns, maintains, and repairs all the
                        lines, wires, poles, and meters in its service area.
                        TDUs are responsibly to fixing power outages.
                      </p>

                      <div className="image-list">
                        <img
                          src={withPrefix('/images/blog/oncor-logo1.svg')}
                          alt="Oncor Logo"
                        />
                        <img
                          src={withPrefix('/images/blog/centerpoint.svg')}
                          alt="CenterPoint Logo"
                        />
                        <img
                          src={withPrefix('/images/blog/aep.svg')}
                          alt="AEP Logo"
                        />
                        <img
                          src={withPrefix('/images/blog/lubbock.svg')}
                          alt="Luubock Logo"
                        />
                      </div>
                    </StepContent>
                  </Step>

                  <Step expanded>
                    <StepLabel>
                      <h2>Retail Electric Providers (REPs)</h2>
                    </StepLabel>
                    <StepContent>
                      <p>
                        Retail Electric Providers are the companies that compete
                        to sell electricity to you. When you sign up for
                        electric service you are signing up with an REP. Your
                        monthly bill will come from an REP.
                      </p>
                      <p>
                        REPs work in conjunction with power generators and TDUs
                        to create, sell, and deliver electricity to your home or
                        business{' '}
                      </p>
                      <div className="image-list providers">
                        <ProviderLogos />
                      </div>
                    </StepContent>
                  </Step>

                  <Step expanded>
                    <StepLabel>
                      <h2>Brokers</h2>
                    </StepLabel>
                    <StepContent>
                      <p>
                        Brokers are companies like SmarterChoice that help
                        consumers navigate the expansive REP market. By
                        consolidating all the REPs and their plans into one easy
                        to understand dashboard, SmarterChoice makes finding the
                        perfect electricity plan quicker and easier than ever
                        before.
                      </p>

                      <div className="image-list">
                        <img
                          src={withPrefix('/images/sc-logo.svg')}
                          className="sc"
                          alt="SmarterChoice Logo"
                        />
                        <br />

                        <img
                          src={withPrefix('/images/blog/comparepower.svg')}
                          alt="ComparePower Logo"
                        />
                        <img
                          src={withPrefix('/images/blog/gatby-logo.png')}
                          alt="Gatby Logo"
                        />
                        <img
                          src={withPrefix('/images/blog/save-on.svg')}
                          alt="SaveOn Logo"
                        />
                      </div>
                    </StepContent>
                  </Step>
                </Stepper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Article);
