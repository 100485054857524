import React from 'react';
import SEO from '../components/SEO';

import Article from '../components/Articles/Energy-101/index';

export default function ({}) {
  return (
    <>
      <SEO
        title={"Energy 101 - Who's who in the Texas Electric Market"}
        description={
          'Learn about all the different players in the Texas Electric Market and how they work together to provide you with electricity.'
        }
        brand={'sc'}
      />

      <Article />
    </>
  );
}
